import { format } from 'date-fns';

const formatDate = (
  date: Date|number,
  formatString: string,
): string => format(date, formatString);

/* eslint-disable */
export {
  formatDate,
};
